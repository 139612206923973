import React from "react";
import styled from "styled-components";

import ProjectItem from "./ProjectItem";

import {
  contentWidth,
  paddingLarge,
  paddingMedium,
  xMarginMobile,
} from "../constants/variables";

const projectsList = [
  {
    title: "Trubadur",
    text: "Gamified music theory e-learning mobile application",
    link: "https://github.com/volkmaster/trubadur",
  },
  {
    title: "Keyword Pattern Generator",
    text: "Creates random patterns based on Twitter, Wikipedia and Google data and applies image filters to them.",
    link: "https://github.com/volkmaster/keyword-pattern-generator",
  },
  {
    title: "Timer Progress",
    text: "Circular Timer Progress Bar",
    link: "https://github.com/volkmaster/timer_progress",
  },
  {
    title: "Circular Slider",
    text: "Circular Slider based on SVG",
    link: "https://github.com/volkmaster/circular-slider",
  },
  {
    title: "News Article Classifier",
    text: "Categorization of news articles with the help of the meta-data from the web using machine learning algorithms.",
    link: "https://github.com/volkmaster/news-article-classifier",
  },
  {
    title: "Cheatsheet",
    text: "Cheatsheet Manager",
    link: "https://github.com/volkmaster/cheatsheet",
  },
  {
    title: "Magic Midi",
    text: "Magic Midi Music Recorder",
    link: "https://github.com/volkmaster/magic-midi",
  },
  {
    title: "Battles of Ancient Greece",
    text: "An interactive learning tool about the battles of the Ancient Greece",
    link: "https://github.com/volkmaster/battles-of-ancient-greece",
  },
];

const Projects = () => (
  <Wrapper>
    <Content>
      <Title>My Projects</Title>
      <Items>
        {projectsList.map((project) => (
          <ProjectItem
            key={project.title}
            title={project.title}
            text={project.text}
            link={project.link}
          />
        ))}
      </Items>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingLarge} ${xMarginMobile};
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  margin-bottom: ${paddingMedium};
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1.5rem 1.5rem;
`;

export default Projects;
