import React from "react";
import styled from "styled-components";

import {
  contentWidth,
  paddingMedium,
  xMarginMobile
} from "../constants/variables";

const mail = require("../images/mail.svg");
const linkedIn = require("../images/linkedin.svg");
const github = require("../images/github.svg");

const Footer = () => (
  <Wrapper>
    <Content>
      <Link href="mailto:ziga.vucko@gmail.com" target="_top" image={mail} />
      <Link
        href="https://www.linkedin.com/in/zigavucko/"
        target="_blank"
        image={linkedIn}
      />
      <Link
        href="https://github.com/volkmaster"
        target="_blank"
        image={github}
      />
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingMedium} ${xMarginMobile};
`;

const Content = styled.div`
  height: 100%;
  max-width: ${contentWidth};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

let Link = ({ href, target, image, ...props }) => (
  <a href={href} target={target} {...props}>
    <Icon src={image} />
  </a>
);

Link = styled(Link)`
  width: 56px;
  height: 56px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;

  &:not(:first-child) {
    margin-left: 24px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

const Icon = styled.img`
  width: 30px;
`;

export default Footer;
