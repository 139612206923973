import React from "react";
import styled from "styled-components";

import {
  contentWidth,
  paddingLarge,
  paddingMedium,
  xMarginMobile,
} from "../constants/variables";

const Skills = () => (
  <Wrapper>
    <Content>
      <Title>Skills</Title>
      <Text>
        For backend development I predominantely use Python, but also Node.js,
        .NET Core & Framework and PHP (Laravel), whereas for the frontend
        development I specialize in React, but also have experience with Angular
        and Vue.js. For mobile development I use Android.
        <br />
        <br />
        I have experience with AWS cloud provider and its following services:
        CloudFormation, IAM, Cognito, API Gateway, Lambda, DynamoDB and S3.
        <br />
        <br />
        I use Docker for building easily reproducible microservice-based
        solutions.
        <br />
        <br />
        I have DevOps experience, working with continuous integration & delivery
        platforms, such as CircleCI, Zeit and Netlify.
        <br />
        <br />
        I build automated software testing suites critical for continuous
        delivery, since I believe tests give you necessary confidence in your
        application.
        <br />
        <br />
        While working in teams I employ Agile methodology practices using Scrum
        and Kanban frameworks on a daily basis.
      </Text>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingMedium} ${xMarginMobile} ${paddingLarge};
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  margin-bottom: ${paddingMedium};
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Text = styled.div`
  width: 100%;
  font-size: 24px;
  text-align: center;
`;

export default Skills;
