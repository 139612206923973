import React from "react";
import styled from "styled-components";

import {
  contentWidth,
  paddingLarge,
  paddingMedium,
  xMarginMobile
} from "../constants/variables";

const WhoAmI = () => (
  <Wrapper>
    <Content>
      <Title>Who Am I</Title>
      <Text>
        I am a fullstack developer with a passion for solving challenges and
        building beautiful software.
        <br />
        <br />
        I am flexible and dynamic developer who is able to learn new
        technologies and start building idiomatic code very quickly.
        <br />
        <br />I am enthusiastic about elegant solutions and sharing ideas with
        other people.
      </Text>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingMedium} ${xMarginMobile} ${paddingLarge};
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  margin-bottom: ${paddingMedium};
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Text = styled.div`
  width: 100%;
  font-size: 24px;
  text-align: center;
`;

export default WhoAmI;
