import React from "react";
import styled, { withTheme } from "styled-components";

import {
  contentWidthNarrow,
  paddingSmall,
  xMarginMobile
} from "../constants/variables";

const computerLight = require("../images/computer-light.svg");
const computerDark = require("../images/computer-dark.svg");

const Cover = ({ theme }) => (
  <Wrapper>
    <Content>
      <Image src={computerLight} show={theme.type === "light"} />
      <Image src={computerDark} show={theme.type === "dark"} />
      <Title>Žiga Vučko</Title>
      <Subtitle>Fullstack Developer</Subtitle>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  height: 85vh;
  padding: ${paddingSmall} ${xMarginMobile};
`;

const Content = styled.div`
  height: 100%;
  max-width: ${contentWidthNarrow};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 150px;
  margin-bottom: 3rem;
  ${({ show }) => !show && "display: none;"}
`;

const Title = styled.div`
  font-size: 34px;
  font-weight: 500;
  text-align: center;
`;

const Subtitle = styled.div`
  margin: 20px 0 80px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.colors.primary};
`;

export default withTheme(Cover);
