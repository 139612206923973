import { colors } from "../constants/variables";

export const lightTheme = {
  type: "light",
  colors: {
    ...colors.shared,
    ...colors.light
  }
};

export const darkTheme = {
  type: "dark",
  colors: {
    ...colors.shared,
    ...colors.dark
  }
};
