import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Switch from "react-switch";

import Cover from "./components/Cover";
import WhoAmI from "./components/WhoAmI";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import { useTheme } from "./theming/useTheme";
import { lightTheme, darkTheme } from "./theming/theme";
import { GlobalStyles } from "./theming/styles";

const App = () => {
  const defaultTheme = "dark";
  const initialTheme = window.localStorage.getItem("theme") || defaultTheme;
  const [theme, toggleTheme] = useTheme(initialTheme);

  const [checked, setChecked] = useState(theme === "light");
  const handleCheck = value => {
    setChecked(value);
    toggleTheme();
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <Wrapper>
          <ThemeSwitch
            checked={checked}
            onChange={handleCheck}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={darkTheme.colors.background}
            offColor={lightTheme.colors.background}
            onHandleColor={darkTheme.colors.text}
            offHandleColor={lightTheme.colors.text}
            handleDiameter={22}
          />
          <Cover />
          <WhoAmI />
          <Skills />
          <Projects />
          <Contact />
          <Footer />
        </Wrapper>
      </>
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
`;

const ThemeSwitch = styled(Switch)`
  position: fixed !important;
  top: 0.5rem;
  right: 0.5rem;
`;

export default App;
