import { useState } from "react";

export const useTheme = initialTheme => {
  const [theme, setTheme] = useState(initialTheme);
  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === "light" ? "dark" : "light";
      window.localStorage.setItem("theme", newTheme);
      return newTheme;
    });
  };

  return [theme, toggleTheme];
};
