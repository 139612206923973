import React from "react";
import styled from "styled-components";

import {
  contentWidth,
  paddingMedium,
  paddingLarge,
  xMarginMobile,
} from "../constants/variables";

const Contact = () => (
  <Wrapper>
    <Content>
      <Title>Contact</Title>
      <Email href="mailto:ziga.vucko@gmail.com">ziga.vucko@gmail.com</Email>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingLarge} ${xMarginMobile};
`;

const Content = styled.div`
  height: 100%;
  max-width: ${contentWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  margin-bottom: ${paddingMedium};
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Email = styled.a`
  font-size: 24px;
`;

export default Contact;
